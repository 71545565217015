// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-farms-tsx": () => import("./../../../src/pages/farms.tsx" /* webpackChunkName: "component---src-pages-farms-tsx" */),
  "component---src-pages-guildbank-tsx": () => import("./../../../src/pages/guildbank.tsx" /* webpackChunkName: "component---src-pages-guildbank-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-play-2-earn-intro-tsx": () => import("./../../../src/pages/play2earn-intro.tsx" /* webpackChunkName: "component---src-pages-play-2-earn-intro-tsx" */),
  "component---src-pages-play-2-earn-tsx": () => import("./../../../src/pages/play2earn.tsx" /* webpackChunkName: "component---src-pages-play-2-earn-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-staking-tsx": () => import("./../../../src/pages/staking.tsx" /* webpackChunkName: "component---src-pages-staking-tsx" */),
  "component---src-pages-staking-v-1-tsx": () => import("./../../../src/pages/staking-v1.tsx" /* webpackChunkName: "component---src-pages-staking-v-1-tsx" */),
  "component---src-pages-vesting-tsx": () => import("./../../../src/pages/vesting.tsx" /* webpackChunkName: "component---src-pages-vesting-tsx" */),
  "component---src-pages-yearly-review-tsx": () => import("./../../../src/pages/yearly-review.tsx" /* webpackChunkName: "component---src-pages-yearly-review-tsx" */),
  "component---src-templates-challenge-detail-tsx": () => import("./../../../src/templates/challengeDetail.tsx" /* webpackChunkName: "component---src-templates-challenge-detail-tsx" */),
  "component---src-templates-pool-tsx": () => import("./../../../src/templates/pool.tsx" /* webpackChunkName: "component---src-templates-pool-tsx" */)
}

